import React, { Component, useEffect, useState, useCallback, useRef } from 'react';
import { cloneDeep } from "lodash";
import familyApi from '../../api/family-api';

import ReactFlow, {
  removeElements,
  addEdge,
  Controls,
  StraightEdge,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
} from 'reactflow';

import 'reactflow/dist/style.css';

import { FATHER_ANCESTRY_ID, MOTHER_ANCESTRY_ID } from './ancestry-node';
import FloatingStepEdge from './floating-step-edge';
import FloatingStraightEdge from './floating-straight-edge';
import FloatingMultipleBirthEdge from './floating-multiple-birth-edge';
import FloatingStraightEdgeNoPartner from './floating-straight-edge-no-partner'
import PersonNode from './person-node';
import PersonConnector from './person-connector-node';
import AncestryNode from './ancestry-node';
import {
  TwinConnectorNodeName,
  ClientSideNodeTypes,
  ApiNodeTypes,
  PedigreeStylesheet,
  MinZoom,
  MaxZoom,
  defaultViewport,
  snapGrid,
  multiSelectionKeyCode
} from './pedigree-constants';


const edgeTypes = {
  TopToBottom: FloatingStraightEdge,
  BottomToChild: FloatingStepEdge,
  PersonToTop: FloatingStraightEdge,
  BottomToTwinChild: FloatingMultipleBirthEdge,
  PersonToBottom: FloatingStraightEdgeNoPartner
};

const nodeTypes = {
  person: PersonNode,
  ancestry: AncestryNode,
  default: PersonConnector
};

const Flow = (props) => {

  return (
    <ReactFlow
      nodesDraggable={!props.readOnlyUser}
      snapToGrid={true}
      snapGrid={snapGrid}
      minZoom={MinZoom}
      maxZoom={MaxZoom}
      defaultZoom={defaultViewport}
      nodes={props.nodes}
      edges={props.edges}
      onNodeClick={props.onNodeClick}
      // onEdgeClick={this.onElementClick}
      onConnect={props.onConnect}
      onConnectStart={props.onConnectStart}
      onConnectEnd={props.onConnectEnd}
      // onElementsRemove={props.onElementsRemove}
      onSelectionChange={props.onSelectionChange}
      onNodeDrag={props.onNodeDrag}
      onNodeDragStop={props.onNodeDragStop}
      onSelectionDragStop={props.onSelectionDragStop}
      // onNodeDragStart={this.onNodeDragStart}
      onInit={(reactFlowInstance) => {
        props.setReactFlowInstance(reactFlowInstance);
        props.onLoad(reactFlowInstance, props.callbacks);
      }}
      onNodesChange={props.onNodesChange}
      onEdgesChange={props.onEdgesChange}
      onMoveEnd={(event, flowTransform) => {
        props.onMoveEnd(event, flowTransform);
      }}
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      elementsSelectable={true}
      onPaneClick={props.onPaneClick}
      zoomOnDoubleClick={false}
      nodeDragThreshold={5}
    // onDragOver={props.onDragOver}
    // onDrop={props.onDrop}
    />
  )
}

// NOTE: React-Flow only likes string's as id's for nodes & edges, and React-Flow
// also does not like duplicate id's
let id = 0;
const getId = () => `dndnode_${id++}`;

const Pedigree = (props) => {
  const reactFlowWrapper = useRef(null);
  const [elementsState, setElementsState] = useState(props.nodes)
  const [nodesState, setNodes, onNodesChange] = useNodesState(props.nodes.filter(element => 'nodeType' in element || element.type == 'ancestry'))
  const [edgesState, setEdges, onEdgesChange] = useEdgesState(props.nodes.filter(element => 'edgeType' in element))
  const [selectedNode, setSelectedNode] = useState(null);
  const [reactFlowInstanceState, setReactFlowInstance] = useState(null);

  // useEffect(() => {
  //   if (selectedNode) {
  //     setNodes((nds) =>
  //       nds.map((node) => {
  //         if (node.id == selectedNode.id) {
  //           node.data = {
  //             ...node.data,
  //             nodeClickCount: selectedNode.data.nodeClickCount + 1,
  //           };
  //           if (node.data.nodeClickCount > 2) {
  //             node.data = {
  //               ...node.data,
  //               nodeClickCount: 1,
  //             };
  //           }
  //         }
  //         else {
  //           node.data = {
  //             ...node.data,
  //             nodeClickCount: 0,
  //           };
  //         }
  //         return node;
  //       })
  //     );
  //   }
  // }, [selectedNode, setNodes]);

  /**********Start class variables***********/
  let parentMap = props.nodeParentMap;
  let nodeDiseaseColorMap = props.nodeDiseaseColorMap;

  let callbacks = {
    setElements: null,
    showAncestry: null
  };


  const cloneElements = (els) => {
    if (reactFlowInstanceState) {
      let nodes = cloneDeep(reactFlowInstanceState.getNodes())
      let edges = cloneDeep(reactFlowInstanceState.getEdges())
      let currentEls = nodes.concat(edges)
      return cloneDeep(currentEls);
    }
    else {
      return cloneDeep(elementsState);
    }

  }

  const setElements = async (els, callback) => {
    let nodes = els.filter(element => 'nodeType' in element || element.type == 'ancestry');
    let edges = els.filter(element => 'edgeType' in element);

    if (callback) {
      setElementsState(els)
      setNodes(nodes);
      setEdges(edges);
      await callback(els)
    }
    else {
      setElementsState(els)
      setNodes(nodes);
      setEdges(edges);
    }
  }

  const setParentMap = (map) => {
    parentMap = map;
  }

  const setNodeDiseaseColorMap = (map) => {
    nodeDiseaseColorMap = map;
  }

  const setNodesSelectedCount = (count) => {
    const els = cloneElements();
    for (let i = 0; i < els.length; i++) {
      if (els[i].type === ClientSideNodeTypes.PERSON || els[i].nodeType === ApiNodeTypes.TOP || els[i].id.startsWith(TwinConnectorNodeName)) {
        els[i].data.nodesSelectedCount = count;
      }
    }
    setElements(els);
  }

  const consanguineousOnConnect = async(params) => {
    let nodes = cloneDeep(reactFlowInstanceState.getNodes())

    let source_node = nodes.find(node => node.id == params.source)
    let target_node = nodes.find(node => node.id == params.target)

    let father_id = '';
    let mother_id = '';
    if(source_node.data.profile.gender !== null && source_node.data.profile.gender.toLowerCase() !== 'u'){

      if(source_node.data.profile.gender.toLowerCase() == 'm') {
        father_id = source_node.id;
        mother_id = target_node.id;
      } else if (source_node.data.profile.gender.toLowerCase() == 'f') {
        father_id = target_node.id;
        mother_id = source_node.id;
      }

    }
    else{
      father_id = target_node.id;
      mother_id = source_node.id;
    }

    // call endpoint for determining if source node is blood related to target node
    let relation_tree = await familyApi.get_family_relation_tree(source_node.data.profile.family_id, source_node.data.profile.id);

    let target_node_is_blood_related = true;
    for(let non_blood_related_id of relation_tree.non_blood_related){
      if(target_node.data.profile.id + "" == non_blood_related_id + ""){
        target_node_is_blood_related = false;
      }
    }

    let payload = {
      "father_id": father_id,
      "mother_id": mother_id,
      "marital_status": target_node_is_blood_related ? null : 'married',
      "is_parent_blood_related": target_node_is_blood_related,
      "blood_relation_type": "",
      "is_manually_connected": true,
    };
    let relationship_data = await familyApi.post_partner_relationship(payload);

    let target_node_profile = props.getPedigreeData().getProfile(target_node.data.profile.rkey);
    let source_node_profile = props.getPedigreeData().getProfile(source_node.data.profile.rkey);

    relationship_data.rkey = `r-${relationship_data.id}`;
    relationship_data.father_id_id = relationship_data.father_id;
    relationship_data.mother_id_id = relationship_data.mother_id;

    target_node_profile.relationship_data = relationship_data;
    let target_relationship_id_already_exists = target_node_profile.relationship_ids.find(relation => relation.id == relationship_data.id)
    if(!target_relationship_id_already_exists){
      target_node_profile.relationship_ids.push(relationship_data);
    }
    else{
      target_node_profile.relationship_ids.map(relation => {
        if(relation.id == relationship_data.id){
          relation.is_parent_blood_related = relationship_data.is_parent_blood_related;
        }
      })
    }

    source_node_profile.relationship_data = relationship_data;
    let source_relationship_id_already_exists = source_node_profile.relationship_ids.find(relation => relation.id == relationship_data.id)
    if(!source_relationship_id_already_exists){
      source_node_profile.relationship_ids.push(relationship_data);
    }
    else{
      source_node_profile.relationship_ids.map(relation => {
        if(relation.id == relationship_data.id){
          relation.is_parent_blood_related = relationship_data.is_parent_blood_related;
        }
      })
    }

    props.getPedigreeData().setProfile(target_node_profile.rkey, target_node_profile);
    props.getPedigreeData().setProfile(source_node_profile.rkey, source_node_profile);
  }

  const reAssignParentsOnConnect = async(params) => {
    let nodes = cloneDeep(reactFlowInstanceState.getNodes());

    let source_node = nodes.find(node => node.id == params.source);
    let target_node = nodes.find(node => node.id == params.target);

    let people = Object.values(props.getPedigreeData().getAllProfiles());
    let peopleWithRelationships = people.filter(person => person.relationship_ids.length > 0);

    let source_node_profile = props.getPedigreeData().getProfile(source_node.data.profile.rkey);

    let target_relationship_rkey = target_node.id.slice(2).slice(0, -2)
    let relationship_data_of_target = peopleWithRelationships.find(person => person.relationship_ids.find(relationship => (relationship.rkey == target_relationship_rkey)));
    relationship_data_of_target = relationship_data_of_target.relationship_ids.find(relationship => relationship.rkey == target_relationship_rkey);

    let payload = {
      member_id: source_node_profile.id,
      bp_id: relationship_data_of_target.id
    }
    let response = await familyApi.update_parents(payload);

    source_node_profile.father_id = response.father.member_id;
    source_node_profile.mother_id = response.mother.member_id;

    props.getPedigreeData().setProfile(source_node_profile.rkey, source_node_profile);
  }

  const onConnect = useCallback(
    async (params) => {
      if(props.getPedigreeData().getConsanguineousConnectionActive()){
        await consanguineousOnConnect(params);
      }
      else if (props.getPedigreeData().getReassignParentsConnectionActive()){
        await reAssignParentsOnConnect(params);
      }

      props.getPedigreeData().setConsanguineousConnectionActive(false);
      props.getPedigreeData().setReassignParentsConnectionActive(false);
      let consanguineous_tooltip = document.querySelectorAll('#consanguineous-tooltip');
      let reassign_parents_tooltip = document.querySelectorAll('#re-assign-parents-tooltip');
      toolTipHide(consanguineous_tooltip)
      toolTipHide(reassign_parents_tooltip)
      window.removeEventListener('mousemove', props.consanguineousToolTipTrack, false);
      window.removeEventListener('mousemove', props.reAssignParentsToolTipTrack, false);

      props.reRenderPedigree();

    },
    [setEdges, reactFlowInstanceState, props.datastore]
  );

  const onConnectStart = useCallback(
    async (event, params) => {
      let profiles = props.getPedigreeData().getAllProfiles();
      let people = Object.values(profiles)

      if(params.handleId == "consanguinity-source"){
        props.getPedigreeData().setConsanguineousConnectionActive(true);

        people.map(person => {
          if(person.id + "" == params.nodeId){
            person.is_consanguineous_source = true; //temporary key
          }
          else{
            delete person.is_consanguineous_source;
          }
          props.getPedigreeData().setProfile(person.rkey, person)
        });

        window.addEventListener('mousemove', props.consanguineousToolTipTrack, false);
      }
      else if(params.handleId == "re-assign-parents-source"){
        props.getPedigreeData().setReassignParentsConnectionActive(true);

        people.map(person => {
          if(person.id + "" == params.nodeId){
            person.is_reassign_parents_source = true; //temporary key
          }
          else{
            delete person.is_reassign_parents_source;
          }
          props.getPedigreeData().setProfile(person.rkey, person)
        });

        window.addEventListener('mousemove', props.reAssignParentsToolTipTrack, false);
      }

      props.reRenderPedigree();
    },
    [setEdges, reactFlowInstanceState, props.datastore]
  );

  const onConnectEnd = useCallback(
    async (event, params) => {
      props.getPedigreeData().setConsanguineousConnectionActive(false);
      props.getPedigreeData().setReassignParentsConnectionActive(false);

      let profiles = props.getPedigreeData().getAllProfiles();
      let people = Object.values(profiles)

      people.map(person => {
        delete person.is_consanguineous_source;
        props.getPedigreeData().setProfile(person.rkey, person)
      });

      let consanguineous_tooltip = document.querySelectorAll('#consanguineous-tooltip');
      let reassign_parents_tooltip = document.querySelectorAll('#re-assign-parents-tooltip');
      toolTipHide(consanguineous_tooltip)
      toolTipHide(reassign_parents_tooltip)
      window.removeEventListener('mousemove', props.consanguineousToolTipTrack, false);
      window.removeEventListener('mousemove', props.reAssignParentsToolTipTrack, false);

      props.reRenderPedigree();
    },
    [setEdges, reactFlowInstanceState, props.datastore]
  );

  const toolTipHide = (tooltip) => {
    for (var i = tooltip.length; i--;) {
      tooltip[i].style.visibility = 'hidden';
    }
  }

  const onElementsRemove = (elementsToRemove) => {
    // setElements((els) => {
    //   removeElements(elementsToRemove, els)
    // });
  }

  const onElementClick = (event, element) => {
    try {
      let render_patient_pedigree = sessionStorage.getItem('render-patient-pedigree')
      render_patient_pedigree = render_patient_pedigree == 'true'

      //error thrown for readonly users from parent node when clickiing so open sidebar without node name check
      if ((props.readOnlyUser || render_patient_pedigree) && 'nodeSelectedCallback' in props) {
        return props.nodeSelectedCallback(element);
      }

      //Clicking on the one click add menus is being considered as a node click, so this is a restriction for that
      // event.preventDefault();

      let nodeName1 = event.target.nodeName
      let nodeName2 = event.target.parentNode.nodeName
      let nodeName3 = event.target.parentNode.parentNode.nodeName

      let modal_twin_click = false;

      let topParent = event.target

      while (topParent !== null) {
        topParent = topParent.parentNode
        if (topParent && topParent.id) {
          if (topParent.id === 'modal-twins') {
            modal_twin_click = true;
          }
        }
      }

      //Make sure clicking the menus doesn't add on node click count
      if (nodeName1 == 'BUTTON' || nodeName2 == 'BUTTON' || nodeName3 == 'BUTTON' || nodeName1 == 'A' || nodeName2 == 'A' || nodeName3 == 'A' || modal_twin_click) {
        return;
      }
      if ('nodeSelectedCallback' in props) {
        props.nodeSelectedCallback(element);
      }
      // addNodeClickCount(element.id);
      setNodes((nds) =>
        nds.map(node => {
          if (node.id === element.id) {
            node.data = {
              ...node.data,
              nodeClickCount: node.data.nodeClickCount + 1,
            };
            if (node.data.nodeClickCount > 2) {
              node.data = {
                ...node.data,
                nodeClickCount: 1,
              };
            }
          }
          else {
            node.data = {
              ...node.data,
              nodeClickCount: 0,
            };
          }
          return node;
        })
      );

      // //check if browser is firefox
      // let f = navigator.userAgent.search("Firefox");
      // if(f > -1){
      //   let currentEls = cloneDeep(this.state.reactFlowInstance.getElements())
      //   //After click release, turn draggable property back to true
      //   for (let el of currentEls) {
      //     if (el.id == element.id) {
      //       el.draggable = true;
      //     }
      //   }

      //   this.setState({ elements: currentEls });
      // }
    }
    catch (e) {
      console.log(e)
    }
  }

  const resetNodeClickCount = () => {
    const els = cloneElements();
    for (let i = 0; i < els.length; i++) {
      if (els[i].type === ClientSideNodeTypes.PERSON || els[i].nodeType === ApiNodeTypes.TOP || els[i].id.startsWith(TwinConnectorNodeName)) {
        delete els[i].data.selected_after_pedigree_load;
        els[i].data.nodeClickCount = 0;
        // await sessionStorage.removeItem('famgenix_nodeClickCount')
        // console.log(sessionStorage.getItem('famgenix_nodeClickCount'))
      }
    }
    setElements(els);
    // setNodes((nds) =>
    //   nds.map(node => {
    //     if (node.type === ClientSideNodeTypes.PERSON || node.nodeType === ApiNodeTypes.TOP || node.id.startsWith(TwinConnectorNodeName)) {
    //       node.data = {
    //         ...node.data,
    //         nodeClickCount: 0,
    //       };
    //       delete node.data.selected_after_pedigree_load;
    //     }
    //     return node;
    //   })
    // );
    sessionStorage.removeItem('famgenix_last_selected_node')
  }



  const onSelectionChange = useCallback((els) => {
    let nodesSelected = els.nodes;

    // resetNodeClickCount();
    // setNodesSelectedCount(nodesSelected.length);
    setNodes((nds) =>
      nds.map(node => {
        if (node.type === ClientSideNodeTypes.PERSON || node.nodeType === ApiNodeTypes.TOP || node.id.startsWith(TwinConnectorNodeName)) {
          node.data = {
            ...node.data,
            nodesSelectedCount: nodesSelected.length,
          };
        }
        return node;
      })
    );

    if ('clearNodeSelectionCallback' in props && nodesSelected.length > 1) {
      props.clearNodeSelectionCallback();
    }
  }, []);

  const getNode = (els, elementID) => {
    let result = null;
    for (let i = 0; i < els.length; i++) {
      let el = els[i];
      let id = el.id.toString();
      if (id === elementID) {
        result = el;
      }
    }
    return result;
  }

  const onNodeDragStart = (event, node) => {
    // let f = navigator.userAgent.search("Firefox");
    // if(f > -1){
    //   let currentEls = cloneDeep(this.state.reactFlowInstance.getElements())
    //   // Clicking a node triggers the onNodeDragStart event and somehow only triggers an issue on firefox
    //   // Set draggable property to false to force it not to drag everytime we click a node
    //   for (let el of currentEls) {
    //     if (el.id == node.id) {
    //       el.draggable = false;
    //     }
    //   }
    //   this.setState({ elements: currentEls }, () => console.log(node.id, currentEls));
    // }
  }

  const onNodeDrag = (event, node) => {
    // console.log("Node Dragging" + node.id);
    // console.log(node);

    // would this help?
    // event.preventDefault();

    // return if Ancestry Node
    if (node.type === ClientSideNodeTypes.ANCESTRY) return;

    let datastore = node.data.datastore;
    let connector = datastore.getSpouseConnector(node.id);
    let nodes = cloneDeep(reactFlowInstanceState.getNodes());
    let edges = cloneDeep(reactFlowInstanceState.getEdges());
    let els = nodes.concat(edges);
    for (let el of els) {
      // set node option draggable back to true to be able to drag
      //  let f = navigator.userAgent.search("Firefox");
      //  if(f > -1){
      //   el.draggable = true;
      //  }
      // let id = el.id.toString();
      if (connector && el.id + '' == connector.node + '') {
        let spouseList = datastore.getSpouse(connector.node);
        let x = el.position.x;
        let y = el.position.y;
        if (spouseList.length === 2) {
          let spouse_a_id = spouseList[0];
          let spouse_a = getNode(els, spouse_a_id);

          if (spouse_a_id === node.id) {
            spouse_a = node;
          }
          else {
            let data = datastore.getNode(spouse_a_id);
            if (!props.saved_data) {
              spouse_a.position.x = data["x"];
              spouse_a.position.y = data["y"];
            }
          }
          let spouse_b_id = spouseList[1];
          let spouse_b = getNode(els, spouse_b_id);
          if (spouse_b_id == node.id) {
            spouse_b = node;
          }
          else {
            let data = datastore.getNode(spouse_b_id);
            if (!props.saved_data) {
              spouse_b.position.x = data["x"];
              spouse_b.position.y = data["y"];
            }
          }
          let diff = Math.abs(spouse_b.position.x - spouse_a.position.x);
          let offset = 50;
          let Y_OFFSET = 18;
          let X_WIDTH_LEFT = 30;
          let left = false;
          let spouse = null;
          if (diff < 125) {
            diff -= 25;
            diff = Math.max(diff, 2);
            offset = diff / 2;
          }
          if (spouse_b.position.x > spouse_a.position.x) {
            if (spouse_b_id == node.id) {
              spouse = spouse_b;
              left = false;
            }
            else {
              spouse = spouse_a;
              left = true;
            }
          }
          else {
            if (spouse_b_id == node.id) {
              spouse = spouse_b;
              left = true;
            }
            else {
              spouse = spouse_a;
              left = false;
            }
          }
          y = spouse.position.y + Y_OFFSET;
          if (left === true) {
            x = spouse.position.x + X_WIDTH_LEFT + offset;
          }
          else {
            x = spouse.position.x - offset;
          }
        }
        el.position = {
          x: x,
          y: y
        };
      }
      else if(el.id + '' == node.id + ''){
        el.position = {
          x: node.position.x,
          y: node.position.y
        }
      }
    }
    setElements(els);
    // this.setElements(els);

    if (node.type === ClientSideNodeTypes.PERSON && !node.data.isNodeDragging) {
      setIsNodeDragging(node.id, true);
    }
  }

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstanceState.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${type} node` },
      };

      setNodes((nds) => nds.concat(newNode));

    },
    [reactFlowInstanceState]
  );

  const addNodeClickCount = (nodeId) => {
    const els = cloneElements();
    for (let i = 0; i < els.length; i++) {
      if (els[i].id === nodeId) {
        els[i].data.nodeClickCount += 1;
        if (els[i].data.nodeClickCount > 2) {
          els[i].data.nodeClickCount = 1
        }
        break;
      }
    }
    setElements(els);
    sessionStorage.setItem('famgenix_last_selected_node', JSON.stringify(nodeId))
  }

  const onNodeDragStop = async (event, node, nodesDropped) => {
    if (node.type === ClientSideNodeTypes.PERSON && node.data.isNodeDragging) {
      setIsNodeDragging(node.id, false);
    }
    // const els = this.cloneElements();
    // for(let el of els){
    //   if(el.id == node.id.toString()){
    //     el.position.x = node.position.x
    //     el.position.y = node.position.y
    //   }
    // }

    //We can just get the realtime positions of elements to be saved from reactflowinstance
    let nodes = cloneDeep(reactFlowInstanceState.getNodes())
    let edges = cloneDeep(reactFlowInstanceState.getEdges())
    let currentEls = nodes.concat(edges)

    let datastore = node.data.datastore;
    let connector = datastore.getSpouseConnector(node.id);

    //set these also to make sure there are no differences on current elements state
    for (let el of currentEls) {
      // if (connector && el.id + '' == connector.node + '') {
      //   let spouseList = datastore.getSpouse(connector.node);
      //   let x = el.position.x;
      //   let y = el.position.y;
      //   if (spouseList.length === 2) {
      //     let spouse_a_id = spouseList[0];
      //     let spouse_a = getNode(currentEls, spouse_a_id);

      //     if (spouse_a_id === node.id) {
      //       spouse_a = node;
      //     }
      //     else {
      //       let data = datastore.getNode(spouse_a_id);
      //       if (!props.saved_data) {
      //         spouse_a.position.x = data["x"];
      //         spouse_a.position.y = data["y"];
      //       }
      //     }
      //     let spouse_b_id = spouseList[1];
      //     let spouse_b = getNode(currentEls, spouse_b_id);
      //     if (spouse_b_id == node.id) {
      //       spouse_b = node;
      //     }
      //     else {
      //       let data = datastore.getNode(spouse_b_id);
      //       if (!props.saved_data) {
      //         spouse_b.position.x = data["x"];
      //         spouse_b.position.y = data["y"];
      //       }
      //     }
      //     let diff = Math.abs(spouse_b.position.x - spouse_a.position.x);
      //     let offset = 50;
      //     let Y_OFFSET = 18;
      //     let X_WIDTH_LEFT = 30;
      //     let left = false;
      //     let spouse = null;
      //     if (diff < 125) {
      //       diff -= 25;
      //       diff = Math.max(diff, 2);
      //       offset = diff / 2;
      //     }
      //     if (spouse_b.position.x > spouse_a.position.x) {
      //       if (spouse_b_id == node.id) {
      //         spouse = spouse_b;
      //         left = false;
      //       }
      //       else {
      //         spouse = spouse_a;
      //         left = true;
      //       }
      //     }
      //     else {
      //       if (spouse_b_id == node.id) {
      //         spouse = spouse_b;
      //         left = true;
      //       }
      //       else {
      //         spouse = spouse_a;
      //         left = false;
      //       }
      //     }
      //     y = spouse.position.y + Y_OFFSET;
      //     if (left === true) {
      //       x = spouse.position.x + X_WIDTH_LEFT + offset;
      //     }
      //     else {
      //       x = spouse.position.x - offset;
      //     }
      //   }
      //   el.position = {
      //     x: x,
      //     y: y
      //   };
      // }

      for (let nodeDropped of nodesDropped) {
        if (el.id == nodeDropped.id) {
          el.data.isNodeDragging = false;
          el.dragging = false;
          if (el.id.startsWith(TwinConnectorNodeName + '-')) {
            el.data.nodeClickCount = 2;
          }
          else {
            el.data.nodeClickCount = 1;
          }
        }
      }
      if (el.id == node.id) {
        el.data.nodeClickCount = 1;
      }
      else {
        el.data.nodeClickCount = 0;
      }
    }

    setElements(currentEls, await props.saveLayoutData(currentEls));
    if ('nodeSelectedCallback' in props) {
      props.nodeSelectedCallback(node);
    }
  }


  const onSelectionDragStop = async (event, nodesDropped) => {
    //We can just get the realtime positions of elements to be saved from reactflowinstance
    let nodes = cloneDeep(reactFlowInstanceState.getNodes())
    let edges = cloneDeep(reactFlowInstanceState.getEdges())
    let currentEls = nodes.concat(edges)
    for (let el of currentEls){
      el.dragging = false;
    }
    setElements(currentEls, await props.saveLayoutData(currentEls));
  }

  const setIsNodeDragging = (nodeId, dragging) => {
    const els = cloneElements();
    for (let i = 0; i < els.length; i++) {
      if (els[i].id === nodeId) {
        els[i].data.isNodeDragging = dragging;
        break;
      }
    }
    setElements(els);
  }

  const showAncestry = (showAncestry) => {
    const els = cloneElements();
    if (showAncestry) {

      for (let i = 0; i < els.length; i++) {
        if (els[i].id === FATHER_ANCESTRY_ID || els[i].id === MOTHER_ANCESTRY_ID) {
          els[i].hidden = false;
        }
      }

    } else {

      for (let i = 0; i < els.length; i++) {
        if (els[i].id === FATHER_ANCESTRY_ID || els[i].id === MOTHER_ANCESTRY_ID) {
          els[i].hidden = true;
        }
      }

    }
    setElements(els);
  }

  const onPaneClick = async () => {
    let consanguineous_tooltip = document.querySelectorAll('#consanguineous-tooltip');
    let reassign_parents_tooltip = document.querySelectorAll('#re-assign-parents-tooltip');
    toolTipHide(consanguineous_tooltip)
    toolTipHide(reassign_parents_tooltip)
    window.removeEventListener('mousemove', props.consanguineousToolTipTrack, false);
    window.removeEventListener('mousemove', props.reAssignParentsToolTipTrack, false);

    if ('clearNodeSelectionCallback' in props) {
      await props.clearNodeSelectionCallback();
    }
    // const els = cloneElements();
    // for (let i = 0; i < els.length; i++) {
    //   els[i].data.nodeClickCount = 0;
    // }
    // await setElements(els);
    // sessionStorage.removeItem('famgenix_last_selected_node')

    setNodes((nds) =>
      nds.map(node => {
        node.data = {
          ...node.data,
          nodeClickCount: 0,
        };
        return node;
      })
    );
    sessionStorage.removeItem('famgenix_last_selected_node')
  }

  // const onNodesChange = (changes) => {
  //   applyNodeChanges(changes, nodesState);
  // }

  const styles = Object.assign({}, PedigreeStylesheet.reactflowWrapper, { height: props.dimensions.height, width: props.dimensions.width });

  callbacks = {
    setElements: setElements,
    showAncestry: showAncestry,
    elements: elementsState
  };

  return (
    <ReactFlowProvider>
      <div id="react-flow-pedigree" style={styles} ref={reactFlowWrapper}>
        <Flow
          readOnlyUser={props.readOnlyUser}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodes={nodesState}
          edges={edgesState}
          onNodeClick={onElementClick}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          onElementsRemove={onElementsRemove}
          onSelectionChange={onSelectionChange}
          setReactFlowInstance={setReactFlowInstance}
          onLoad={props.onLoad}
          callbacks={callbacks}
          multiSelectionKeyCode={multiSelectionKeyCode()}
          // onNodesChange={onNodesChange}
          onPaneClick={onPaneClick}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          // onDrop={onDrop}
          // onDragOver={onDragOver}
          onSelectionDragStop={onSelectionDragStop}
          onMoveEnd={(event, flowTransform) => {
            props.onMoveEnd(event, flowTransform)
          }}
        />
      </div>
    </ReactFlowProvider>
  );
}


export default Pedigree;
