import React, { Component } from 'react';
import { connect } from "react-redux";

import helpers from '../helpers/index';
import images from '../configs/images';
import NavTop from '../components/nav-top';
import ModalUserProfile from '../components/user-profile/modal-user-profile';
import ModalResetPassword from '../components/user-profile/modal-reset-password';
import ModalDevKey from '../components/user-profile/modal-developer-key'
import * as action_auth from '../store/session/actions';
import user_account_api from "../api/user-account-api";
import sdk from "../api/sdk";
import route_helper from '../route-helper';

const SSO_LOGIN_TYPES = ["saml", "oauth"]

class UserProfilePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accountName: '',
      clinicianCode: '',
      showUserProfileModal: false,
      showResetPasswordModal: false,
      savedUserProfileMessage: null,
      showDevKeyModal: false,
      savedPasswordMessage: null,
      passwordErrorMessage: null,
      showAuthenticator: null
    };

    this.openUserProfileModal  = this.openUserProfileModal.bind(this);
    this.closeUserProfileModal = this.closeUserProfileModal.bind(this);
    this.onSaveUserProfile = this.onSaveUserProfile.bind(this);

    this.openResetPasswordModal  = this.openResetPasswordModal.bind(this);
    this.displayPasswordModal  = this.displayPasswordModal.bind(this);
    this.closeResetPasswordModal = this.closeResetPasswordModal.bind(this);
    this.onSaveUserPassword = this.onSaveUserPassword.bind(this);
    this.clearUserProfileMessage = this.clearUserProfileMessage.bind(this);
    this.clearPasswordMessage = this.clearPasswordMessage.bind(this);
    this.displayDevKeyModal = this.displayDevKeyModal.bind(this);
    this.closeDevModal = this.closeDevModal.bind(this);
    this.registerAuthenticator = this.registerAuthenticator.bind(this);
  }

  async componentDidMount() {
    helpers.setSiteTitle('User Profile');
    let { clinician_code, org } = await user_account_api.get_org_by_id(this.props.session.user.organization_id);
    this.setState({accountName: org.org_name, clinicianCode: clinician_code.code});

    let has_sso = true
    for (let i = 0; i < SSO_LOGIN_TYPES.length; i++) {
      if (this.props.session.user.login_type == SSO_LOGIN_TYPES[i]) {
        has_sso = false
      }
    }
    // this.setState({showAuthenticator: has_sso});
    this.setState({showAuthenticator: false});
  }

  openUserProfileModal(event) {
    this.setState({showUserProfileModal: true});
  }

  closeUserProfileModal(event) {
    this.setState({showUserProfileModal: false});
  }

  async onSaveUserProfile(payload) {
    // do not need a deep clone here, user only have top level properties no nested objects/arrays
    let cloned_payload = Object.assign({}, payload);

    // send saved data to the server
    let user_profile = await user_account_api.post_update_user_profile(this.props.session.user.staff_id, {
      first_name: cloned_payload.first_name,
      last_name: cloned_payload.last_name,
      email: cloned_payload.email
    });

    this.props.dispatch(action_auth.user_save(cloned_payload));

    this.setState({ savedUserProfileMessage: 'Successfully saved user profile' });
    const clearMessage = this.clearUserProfileMessage;
    setTimeout(function() {
      clearMessage();
    }, 5000);
  }

  clearUserProfileMessage() {
    this.setState({ savedUserProfileMessage: null });
  }

  openResetPasswordModal(event) {
    this.displayPasswordModal(null);
  }

  closeResetPasswordModal(event) {
    this.setState({showResetPasswordModal: false});
  }

  closeDevModal(){
    this.setState({ showDevKeyModal: false })
  }


  displayPasswordModal(error) {
    this.setState({
      showResetPasswordModal: true,
      passwordErrorMessage: error,
      savedPasswordMessage: null,
      showDevKeyModal: false
    });
  }

  displayDevKeyModal(){
    this.setState({
      showResetPasswordModal: false,
      savedPasswordMessage: null,
      showDevKeyModal: true
    });
  }

  registerAuthenticator() {
    const { from } = this.props.location.state || { from: { pathname: route_helper.authenticator.registration } };
    this.props.history.replace(from);
  }

  async onSaveUserPassword(payload) {
    try {
      // send data to the server, DO NOT SAVE PASSWORD DETAILS IN REDUX
      const message = await user_account_api.post_update_user_password(this.props.session.user.staff_id, {
        current_password: payload.current_password,
        new_password: payload.new_password,
        confirm_password: payload.confirm_password
      });

      this.setState({ savedPasswordMessage: 'Successfully saved user password' });

      const clearMessage = this.clearPasswordMessage;
      setTimeout(function() {
        clearMessage();
      }, 5000);

    } catch (error) {
      this.displayPasswordModal(error.message);
    }
  }

  clearPasswordMessage() {
    this.setState({ savedPasswordMessage: null });
  }

  render() {
    return (
      <React.Fragment>

        <NavTop history={this.props.history} />

        <section className="wow fadeIn section animated" style={{visibility: 'visible', animationName: 'fadeIn'}}>
          <div className="container">
            <div className="section-header">
              <div className="row vcenter">
                <div className="col-md-6 col-xs-12">
                  <h2>Your Profile</h2>
                </div>
                <div className="col-md-4 col-md-offset-2  col-xs-12 justify-right hidden"></div>
              </div>
            </div>

            {this.state.savedUserProfileMessage !== null && (
              <p className="alert-success">{this.state.savedUserProfileMessage}</p>
            )}

            {this.state.savedPasswordMessage !== null && (
              <p className="alert-success">{this.state.savedPasswordMessage}</p>
            )}

            <div className="row">
              <div className="col-md-12">
                <div className="clearfix"></div>
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                      <h3 className="company-name">{this.state.accountName}</h3>
                      <p className="media-date">Clinician Code: {this.state.clinicianCode}</p>
                   </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-12">
                    <hr />
                    <div className="row">
                      <div className="col-md-1 col-xs-12">
                        <img src={images.cliniciansProfileAvatar} className="margin-four-bottom" alt="user profile avatar" />
                      </div>

                      <div className="col-md-9">
                        <p className="media-heading color-purple m-r-18">{this.props.session.user.first_name} {this.props.session.user.last_name}</p>
                        <p className="media-date">Email: {this.props.session.user.email}</p>
                      </div>

                      <div className="col-md-2">
                        <button type="button" className="btn btn-teal" onClick={this.openUserProfileModal}><i className="fa fa-pencil text-white"></i>Edit Profile</button>
                      </div>
                    </div>
                    <hr />
                    {this.state.showAuthenticator && (
                      <button type="button" className="btn btn-light-outline" onClick={this.registerAuthenticator}>Register Authenticator</button>
                    )}
                    {this.props.session.user.admin && (
                       <button type="button" className="btn btn-light-outline" onClick={this.displayDevKeyModal}>Generate Dev. Key</button>
                    )}
                    <button type="button" className="btn btn-light-outline" onClick={this.openResetPasswordModal}>Reset Password</button>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </section>


        {this.state.showUserProfileModal &&
          <ModalUserProfile
            user={this.props.session.user}
            onClose={this.closeUserProfileModal}
            onClickSave={(payload) => this.onSaveUserProfile(payload)}
          />
        }

        {this.state.showResetPasswordModal &&
          <ModalResetPassword
            onClose={this.closeResetPasswordModal}
            onClickSave={(payload) => this.onSaveUserPassword(payload)}
            errorMessage={this.state.passwordErrorMessage}
          />
        }
        {this.state.showDevKeyModal &&
          <ModalDevKey
          user={this.props.session.user}
          onClose={this.closeDevModal}
          />
        }

      </React.Fragment>

    );
  }
}

const redux_state = state => ({
    session: state.session
});

const redux_actions = dispatch => ({
    dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(UserProfilePage);
